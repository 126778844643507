import React from 'react'
import classnames from 'classnames'

import * as css from './ScreenshotsCarousel.module.css'

const ScreenshotsCarousel = ({id, title, subTitle, slides}) => {
  return <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
    <div className={classnames('row', 'text-center', 'align-items-center', 'g-5', 'pt-5')}>
      <h2 className="mt-0">{title}</h2>
      <p className='lead'>{subTitle}</p>
    </div>
    <div className={classnames('row', 'justify-content-center', 'align-items-center', 'g-5', 'py-5')} >
      <div
        id={id}
        className={classnames('carousel', 'slide', 'carousel-dark')}
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {slides.map((_slide, index) => (
            <button
              type="button"
              data-bs-target={`#${id}`}
              data-bs-slide-to={index}
              className="active"
              aria-current={index === 0 ? 'true' : 'false'}
              aria-label={`Slide ${index}`}
            ></button>
          ))}
        </div>
        <div className={classnames( 'carousel-inner', 'row', 'justify-content-center')} >
          <div className={classnames('col-xxl-10')}>
            {slides.map((slide, index) => (
              <div className={classnames('carousel-item', {active: index === 0})}>
                <img src={slide.image} className={classnames("d-block", "mx-lg-auto", "img-fluid", css.slide)} alt={slide.alt} width={slide.width} loading="lazy" />
                <div class={classnames("carousel-caption", "d-none", "d-md-block", css.slideCaption)} style={{position: 'initial'}}>
                  <h3>{slide.title}</h3>
                  <p>{slide.subTitle}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={`#${id}`}
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={`#${id}`}
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  </div>
}

export default ScreenshotsCarousel
