import React from "react"
import classnames from "classnames"

import dotsImage from '../../images/newdesign/dots.svg'

import * as css from './ValueSection.module.css'

const WhyIntegrationPolish = () => {
  return <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
    <div className={classnames('row', 'text-center', 'align-items-center', 'g-5', 'pt-5')}>
      <h2 className="mt-0">Dlaczego warto się zintegrować</h2>
      <img src={dotsImage} className="d-block" alt="Create icon" width="80" height="20" loading="lazy" />
    </div>
    <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
      <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
        <h3>Głębokie zrozumienie wzrostu</h3>
        <p>Zrozumiesz jakie produkty przynoszą najwięcej przychodu, w co warto inwestować a co przestać sprzedawać</p>
      </div>
      <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
        <h3>Organizacja</h3>
        <p>Doradzimy jak wystawiać faktury, żeby analityka na ich podstawie była pewna</p>
      </div>
    </div>
    <div className={classnames('row', 'justify-content-center', 'g-5', 'py-5', 'd-flex')}>
      <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
        <h3>Zadbamy o dane historyczne</h3>
        <p>Pomożemy Ci z importem danych historycznych</p>
      </div>
      <div className={classnames("col", "d-flex", 'flex-column', css.valueCard)}>
        <h3>Automatyzacja</h3>
        <p>Wszystkie dane spływaja na bieżąco, koniec z czekaniem całymi dniami na wyniki firmy</p>
      </div>
    </div>
  </div>
}

export default WhyIntegrationPolish
