import React from 'react'
import Layout from '../../components/newdesign/layout'
import Testimonial from '../../components/Testimonial'
import Seo from '../../components/seo'
import classnames from 'classnames'
import shareImage from '../../images/integrations/fakturownia/share-image.png'
import fakturowniaImage from '../../images/logos/fakturownia-logo-light.svg'
import CustomersPromo from '../../components/CustomersPromo'
import Casestudies from '../../components/newdesign/Casestudies'
import WhyIntegrationPolish from '../../components/newdesign/WhyIntegratePolish'
import ScheduleDemoSection from '../../components/ScheduleDemoSection'
import {CustomersTableUsecases, ReportingUsecases, DetailsUsecases} from '../../components/newdesign/CustomersTableUsecase'
import * as css from '../../components/casestudy/caseStudyLayout.module.css'

const Integrations = () => (
  <Layout nakedBar trialCta slackApp pricing>
    <Seo
      title="Integracja Fakturownia dla E-commerce, Services, SaaS"
      description={
        'Integracja Probe + Fakturownia. Bezproblemowa analityka biznesowa na podstawie faktur w Fakturowni.'
      }
      keywords={['integracja', 'fakturownia', 'e-commerce', 'services', 'saas', 'mrr', 'bi', 'analityka']}
      twitterImg={shareImage}
      mainpageImg={shareImage}
    />
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'flex-lg-row-reverse', 'align-items-center', 'g-5', 'py-3')}>
        <div className={classnames('col-10', 'col-sm-8', 'col-lg-6')}>
          <img src={fakturowniaImage} className="d-block mx-lg-auto img-fluid" alt="Fakturownia logo" width="400" loading="lazy" />
        </div>
        <div className={classnames('col-lg-6')}>
          <h1 className={classnames('display-5', 'fw-bold', 'lh-1', 'mb-3')}>E-commerce, Services, SaaS - wyniki finansowe zawsze pod reka</h1>
          <p className='lead'>
          Dzięki integracji Fakturowni z Probe w bezproblemowy sposób zobaczysz swoje metryki finansowe i będziesz miał głębokie zrozumienie wzrostu biznesu
          </p>
          <a className={classnames('btn', css.ctaFilled)} href="https://app.getprobe.io/#/sign_up" target='_blank' rel="noreferrer">Wyprobuj za darmo</a>
        </div>
      </div>
    </div>
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'flex-lg-row-reverse', 'align-items-center')}>
        <Testimonial
          person="Bogdan Tyskyy"
          title="Chief Revenue Officer"
          url="https://callpage.io"
        >
          "Używamy Fakturowni. Przed integracją z Probe potrzebowaliśmy 10-15 dni
          żeby mieć pełne wyniki miesięczne. Dzisiaj mamy je pierwszego dnia
          miesiąca bez żadnej pracy."
        </Testimonial>
      </div>
    </div>
    <WhyIntegrationPolish />
    <CustomersTableUsecases />
    <ReportingUsecases />
    <DetailsUsecases />
    <Casestudies polish/>
    <CustomersPromo />
    <ScheduleDemoSection />
  </Layout>
)

export default Integrations
