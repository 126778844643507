import React from 'react'
import classnames from 'classnames'
import { CaseStudyCards } from '../casestudy/caseStudyLayout'

const Casestudies = ({polish}) => {
  return <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
    <div className={classnames('row', 'align-items-center', 'g-5', 'pt-5')}>
      {polish && <h2 className="mt-0">Więcej o naszej współpracy z klientami</h2>}
      {!polish && <h2 className="mt-0">Learn more about our work with customers</h2>}
    </div>
    <div className={classnames('row', 'align-items-center', 'g-5', 'py-5')}>
      <CaseStudyCards names={['devskiller', 'edrone', 'castle']} />
    </div>
  </div>
}

export default Casestudies
