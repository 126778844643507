import React from 'react'

import customerTableImg from '../../images/newdesign/customers_table.png'
import customersColumnsImg from '../../images/newdesign/customers_columns.png'
import customersColumnCreateImg from '../../images/newdesign/customers_column_create.png'

import revenueImg from '../../images/newdesign/revenue.png'
import existingBusinessRevenue from '../../images/newdesign/existing_biz_revenue.png'
import newBusinessRevenue from '../../images/newdesign/new_biz_revenue.png'

import customerDetails from '../../images/newdesign/customer_details.png'
import metricDetails from '../../images/newdesign/metric_details.png'

import ScreenshotsCarousel from './ScreenshotsCarousel'

export const DetailsUsecases = () => {
  const slides = [
    {
      image: metricDetails,
      alt: 'Details behind every metric',
      width: 800,
      title: 'Calculation deep dive',
      subTitle: 'You can access lists of customers behind every calculation with one click',
    },
    {
      image: customerDetails,
      alt: 'Details of every customer',
      width: 800,
      title: 'Customer details',
      subTitle: 'Every customer has a separate page where you can review its payment history and performance over time',
    },
  ]

  return <ScreenshotsCarousel
    id='detailsAccessUsecase'
    slides={slides}
    title='Access details behind every chart and calculation'
    subTitle='Data trust is critical for the success of any analytics. You can access details of every metric we show and close your month with confidence that you have not missed a thing.'
  />
}

export const ReportingUsecases = () => {
  const slides = [
    {
      image: existingBusinessRevenue,
      alt: 'Existing business revenue',
      width: 800,
      title: 'Existing business',
      subTitle: 'Flexible way to visualize your results and dive deep into your financial performance',
    },
    {
      image: revenueImg,
      alt: 'Business revenue',
      width: 800,
      title: 'Revenue',
      subTitle: "Every chart can be segmented by different customer's attributes",
    },
    {
      image: newBusinessRevenue,
      alt: 'New business revenue',
      width: 800,
      title: 'New business revenue',
      subTitle: 'Understand how many customers and how much money your bring from a completely new clients every month',
    },
  ]

  return <ScreenshotsCarousel
    id='reportingUsecase'
    slides={slides}
    title='Flexible all-in-one reporting platform'
    subTitle='Everything you need to understand growth of your business, from basic revenue charts to dynamic segmentations, understanding of sold quantities, given disounts and corrections.'
  />
}

export const CustomersTableUsecases = () => {
  const slides = [
    {
      image: customerTableImg,
      alt: 'Customers table',
      width: 800,
      title: 'All of your customers in one place',
      subTitle: 'Flexible table where you can review and compare all of your current clients',
    },
    {
      image: customersColumnsImg,
      alt: 'Flexible columns',
      width: 800,
      title: 'Flexible columns',
      subTitle: 'You can decide what attributes do you want to see and in what order',
    },
    {
      image: customersColumnCreateImg,
      alt: 'Custom attributes',
      width: 800,
      title: 'Calculate custom metrics',
      subTitle: 'Add new formula based attributes to understand performance of your clients',
    },
  ]

  return <ScreenshotsCarousel
    id='customersTableUsecase'
    slides={slides}
    title='All of your customers in one place'
    subTitle='One go to place to get all information about your customer base. Any customer related custom metric at your fingertips.'
  />
}
